import { businessFlow } from "../../components/Onboarding/onboarding.helper";

const signupEvents = {
  SIGNUP_VIEW: {
    event_name: "signup_view",
    event_id: "1001",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
  SIGNUP_CONTINUE_CLICKED: {
    event_name: "signup_continue_clicked",
    event_id: "1002",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
};

const loginEvents = {
  LOGIN_VIEW: {
    event_name: "login_view",
    event_id: "1005",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
  LOGIN_CONTINUE_CLICKED: {
    event_name: "login_continue_clicked",
    event_id: "1006",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
};

const homepageEvents = {
  HOMEPAGE_VIEW: {
    event_name: "homepage_view",
    event_id: "1009",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
  HOMEPAGE_FOREVER_LEARNMORE_CLICKED: {
    event_name: "homepage_forever_learnmore_clicked",
    event_id: "1010",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
  HOMEPAGE_CARE_LEARNMORE_CLICKED: {
    event_name: "homepage_care_learnmore_clicked",
    event_id: "1011",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
  HOMEPAGE_EVERYDAY_LEARNMORE_CLICKED: {
    event_name: "homepage_everyday_learnmore_clicked",
    event_id: "1012",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
  HOMEPAGE_FOREVER_BUYNOW_CLICKED: {
    event_name: "homepage_forever_buynow_clicked",
    event_id: "1013",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
  HOMEPAGE_CARE_BUYNOW_CLICKED: {
    event_name: "homepage_care_buynow_clicked",
    event_id: "1014",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
  HOMEPAGE_EVERYDAY_BUYNOW_CLICKED: {
    event_name: "homepage_everyday_buynow_clicked",
    event_id: "1015",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
  HOMEPAGE_COMPAREPLANS_CLICKED: {
    event_name: "homepage_compareplans_clicked",
    event_id: "1016",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },

  // TODO

  HOMEPAGE_HERO_SECTION: {
    event_name: "homepage_section0",
    event_id: "1122",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },

  HOMEPAGE_HOSPITAL_NETWORK: {
    event_name: "homepage_section1",
    event_id: "1123",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },

  HOMEPAGE_BENEFIT_SECTION: {
    event_name: "homepage_section2",
    event_id: "1124",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },

  HOMEPAGE_PLAN_SECTION: {
    event_name: "homepage_section3",
    event_id: "1125",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },

  HOMEPAGE_KENKO_ADVANTAGE_SECTION: {
    event_name: "homepage_section4",
    event_id: "1126",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },

  HOMEPAGE_KENKO_VERSE_SECTION: {
    event_name: "homepage_section5",
    event_id: "1127",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },

  HOMEPAGE_TRUSTED_BY_SECTION: {
    event_name: "homepage_section6",
    event_id: "1128",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },

  HOMEPAGE_TESTIMONIAL_SECTION: {
    event_name: "homepage_section7",
    event_id: "1129",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },

  HOMEPAGE_BLOGS_SECTION: {
    event_name: "homepage_section8",
    event_id: "1130",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },

  HOMEPAGE_HEADER: {
    event_name: "homepage_header",
    event_id: "1017",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },

  HOMEPAGE_OURPLANS: {
    event_name: "homepage_ourplans",
    event_id: "1018",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },

  HOMEPAGE_HOWDOESITWORK: {
    event_name: "homepage_howdoesitwork",
    event_id: "1019",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
  HOMEPAGE_SHOHO: {
    event_name: "homepage_shoho",
    event_id: "1020",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
  HOMEPAGE_TRUSTEDBY: {
    event_name: "homepage_trustedby",
    event_id: "1021",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
};

const planSelectionEvents = {
  PLANSELECTION_VIEW: {
    event_name: "planselection_view",
    event_id: "1022",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
  PLANSELECTION_FOREVER_LEARNMORE_CLICKED: {
    event_name: "planselection_forever_learnmore_clicked",
    event_id: "1023",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
  PLANSELECTION_CARE_LEARNMORE_CLICKED: {
    event_name: "planselection_care_learnmore_clicked",
    event_id: "1024",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
  PLANSELECTION_EVERYDAY_LEARNMORE_CLICKED: {
    event_name: "planselection_everyday_learnmore_clicked",
    event_id: "1025",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
  PLANSELECTION_FOREVER_BUYNOW_CLICKED: {
    event_name: "planselection_forever_buynow_clicked",
    event_id: "1026",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
  PLANSELECTION_CARE_BUYNOW_CLICKED: {
    event_name: "planselection_care_buynow_clicked",
    event_id: "1027",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
  PLANSELECTION_EVERYDAY_BUYNOW_CLICKED: {
    event_name: "planselection_everyday_buynow_clicked",
    event_id: "1028",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
  PLANSELECTION_COMPAREPLANS_CLICKED: {
    event_name: "planselection_compareplans_clicked",
    event_id: "1029",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
};

const planComparisonEvents = {
  COMPARISON_VIEW: {
    event_name: "comparison_view",
    event_id: "1030",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
  COMPARISON_FOREVER_LEARNMORE_CLICKED: {
    event_name: "comparison_forever_learnmore_clicked",
    event_id: "1031",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
  COMPARISON_CARE_LEARNMORE_CLICKED: {
    event_name: "comparison_care_learnmore_clicked",
    event_id: "1032",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
  COMPARISON_EVERYDAY_LEARNMORE_CLICKED: {
    event_name: "comparison_everyday_learnmore_clicked",
    event_id: "1033",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
  COMPARISON_FOREVER_BUYNOW_CLICKED: {
    event_name: "comparison_forever_buynow_clicked",
    event_id: "1034",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
  COMPARISON_CARE_BUYNOW_CLICKED: {
    event_name: "comparison_care_buynow_clicked",
    event_id: "1035",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
  COMPARISON_EVERYDAY_BUYNOW_CLICKED: {
    event_name: "comparison_everyday_buynow_clicked",
    event_id: "1036",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
};

const planPageEvents = {
  PLANPAGE_VIEW: {
    event_name: "planpage_view",
    event_id: "1037",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
  PLANPAGE_SUBSCRIBENOW_CLICKED: {
    event_name: "plan_subscribenow_clicked",
    event_id: "1038",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
  PLANPAGE_VIEWPLANS_CLICKED: {
    event_name: "planpage_viewplans_clicked",
    event_id: "1039",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
  // TODO: Section events
  PLANPAGE_SECTION0: {
    event_name: "planpage_section0",
    event_id: "1040",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
  PLANPAGE_SECTION1: {
    event_name: "planpage_section1",
    event_id: "1041",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
  PLANPAGE_SECTION2: {
    event_name: "planpage_section2",
    event_id: "1042",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
  PLANPAGE_SECTION3: {
    event_name: "planpage_section3",
    event_id: "1043",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
};

const purchasePathEvents = {
  DEPENDENT_VIEW: {
    event_name: "dependent_view",
    event_id: "1044",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
  DEPENDENT_CONTINUE_CLICKED: {
    event_name: "dependent_continue_clicked",
    event_id: "1045",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
  DECLARATION1_CONTINUE_CLICKED: {
    event_name: "declaration1_continue_clicked",
    event_id: "1046",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
  DECLARATION2_CONTINUE_CLICKED: {
    event_name: "declaration2_continue_clicked",
    event_id: "1047",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
  CHECKOUT_VIEW: {
    event_name: "checkout_view",
    event_id: "1048",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
  CHECKOUT_APPLYCOUPON_CLICKED: {
    event_name: "checkout_applycoupon_clicked",
    event_id: "1050",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
  CHECKOUT_COUPON_APPLIED: {
    event_name: "checkout_coupon_applied",
    event_id: "1114",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
  CHECKOUT_COUPON_INVALID: {
    event_name: "checkout_coupon_invalid",
    event_id: "1115",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
  CHECKOUT_REMOVECOUPON_CLICKED: {
    event_name: "checkout_removecoupon_clicked",
    event_id: "1116",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
  CHECKOUT_PAYNOW_CLICKED: {
    event_name: "checkout_paynow_clicked",
    event_id: "1052",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
  CHECKOUT_24M_CLICKED: {
    event_name: "checkout_24M_clicked",
    event_id: "1053",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
  CHECKOUT_12M_CLICKED: {
    event_name: "checkout_12M_clicked",
    event_id: "1054",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
  CHECKOUT_6M_CLICKED: {
    event_name: "checkout_6M_clicked",
    event_id: "1055",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
  CHECKOUT_3M_CLICKED: {
    event_name: "checkout_3M_clicked",
    event_id: "1056",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
  CHECKOUT_WAITINGPERIOD_CLICKED: {
    event_name: "checkout_waitingperiod_clicked",
    event_id: "1057",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
  CHECKOUT_OUTOFSCOPE_CLICKED: {
    event_name: "checkout_outofscope_clicked",
    event_id: "1058",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
  STATUS_SUCCESS_OPENED: {
    event_name: "status_success_opened",
    event_id: "1061",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
  STATUS_PENDING_OPENED: {
    event_name: "status_pending_opened",
    event_id: "1062",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
  STATUS_FAIL_OPENED: {
    event_name: "status_fail_opened",
    event_id: "1063",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
  STATUSFAILED_TRYAGAIN_CLICKED: {
    event_name: "statusfailed_tryagain_clicked",
    event_id: "1064",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
  STATUSFAILED_CONTACTSUPPORT_CLICKED: {
    event_name: "statusfailed_contactsupport_clicked",
    event_id: "1065",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
};

const referralEvents = {
  REFERRALDETAILS_VIEW: {
    event_name: "referraldetails_view",
    event_id: "1102",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
  REFERRALDETAILS_SHAREREFERRAL_CLICK: {
    event_name: "referraldetails_sharereferral_click ",
    event_id: "1103",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
  TRACKREFERRAL_VIEW: {
    event_name: "trackreferral_view",
    event_id: "1105",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
  TRACKREFERRAL_SHAREREFERRAL_CLICK: {
    event_name: "trackreferral_sharereferral_click",
    event_id: "1106",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
  TRACKREFERRAL_REMINDTHEM_CLICK: {
    event_name: "trackreferral_remindthem_click",
    event_id: "1107",
    _v: "1.0.0",
    ...businessFlow.RETAIL,
  },
};

export const backendEvents = {
  // Signup page
  ...signupEvents,

  // Login page
  ...loginEvents,

  // Homepage
  ...homepageEvents,

  // Plan selection page
  ...planSelectionEvents,

  // Plan comparison page
  ...planComparisonEvents,

  // Plan page events
  ...planPageEvents,

  // Purchase path events
  ...purchasePathEvents,

  // Referral events
  ...referralEvents,
};
