import React from "react";
import styled from "@emotion/styled";

const TypographyWrapper = styled.div`
  .typography {
    ${"" /* font-family: Poppins; */}
  }

  .typography-h1 {
    font-size: 72px;
    font-weight: bold;
    letter-spacing: -1.5px;
  }

  .typography-h2 {
    font-size: 60px;
    font-weight: bold;
    letter-spacing: -0.5px;
  }

  .typography-h3 {
    font-size: 48px;
    font-weight: bold;
    letter-spacing: 0px;
  }

  .typography-h4 {
    font-size: 34px;
    font-weight: bold;
    letter-spacing: 0.25px;
  }

  .typography-h5 {
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0px;
  }

  .typography-h6 {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.15px;
  }

  .typography-subtitle1 {
    font-size: 19px;
    font-weight: 400;
    letter-spacing: 0.15px;
  }

  .typography-subtitle2 {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.1px;
  }

  .typography-body1 {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.5px;
  }

  .typography-body2 {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.25px;
  }

  .typography-body3 {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.25px;
  }

  .typography-button1 {
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 1.25px;
  }

  .typography-button2 {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1.25px;
  }

  .typography-button3 {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1.25px;
  }

  .typography-button4 {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 1.25px;
  }

  .typography-textbutton1 {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1.25px;
  }

  .typography-textbutton2 {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1.25px;
  }

  .typography-textbutton3 {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 1.25px;
  }

  .typography-caption1 {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.4px;
  }

  .typography-caption2 {
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0.4px;
  }

  .typography-overline {
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 1.5px;
    text-transform: uppercase;
  }

  .typography-hero {
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.0015em;
    text-transform: uppercase;
    line-height: 24px;
  }

  .typography-sub-text {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.001em;
    text-transform: uppercase;
    line-height: 16px;
  }
  @media (max-width: 400px) {
    .typography-hero {
      font-size: 18px;
    }
    .typography-sub-text {
      font-size: 14px;
    }
  }

  @media (max-width: 350px) {
    .typography-hero {
      font-size: 16px;
    }
    .typography-sub-text {
      font-size: 12px;
    }
  }
  @media (max-width: 325px) {
    .typography-hero {
      font-size: 12px;
    }
    .typography-sub-text {
      font-size: 11px;
    }
  }

  .typography-sub-text-2 {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.001em;
    text-transform: uppercase;
    line-height: 16px;
    color: #003032;
  }

  .typography-striked-text {
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    line-height: 24px;
    text-decoration-line: line-through;
    color: #003032;
    opacity: 0.3;
  }

  .typography-cart-sub-price {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 24px;
    color: #003032;
  }

  .typography-cart-child-name {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    opacity: 0.9;
    color: #003032;
  }
  .typography-discount-text {
    border-radius: 26.6667px;
    padding: 2px 10px 2px 10px;
    font-size: 14px;
    text-align: center;
    margin-top: 3px;
    background-color: #bad19d80;
  }

  @media (max-width: 350px) {
    .typography-discount-text {
      width: max-content;
      padding: 2px 4px 2px 4px;
    }
  }
`;

export default function Typography({
  children,
  variant,
  style,
  className,
  wrapperClass,
}: any) {
  return (
    <TypographyWrapper className={wrapperClass}>
      <div
        className={["typography", `typography-${variant}`, className].join(" ")}
        style={style}
      >
        {children}
      </div>
    </TypographyWrapper>
  );
}
